import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import MuiModal from '@mui/material/Modal';
import groupBy from 'lodash/groupBy';

import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListSubheader from '@mui/material/ListSubheader';
import MuiListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { grey, blue } from '@mui/material/colors';

import { LinearProgress, styled } from '@mui/material';
import SearchForm from './form';

import { createLogger } from '../../utils/logger';
import { Routes } from '../../utils/constants/routes';
import { EntityTypes } from '../../utils/constants/entityTypes';
const log = createLogger('SearchModal');

const greyBG = '#504f52';

const Modal = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Paper = styled('div')({
  position: 'absolute',
  width: 600,
  height: 500,
  backgroundColor: greyBG,
  color: 'white',
  overflow: 'hidden',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
});

const List = styled(MuiList)({
  height: 464,
  overflowY: 'scroll',
  backgroundColor: grey[600],
  margin: 0,
  padding: 0,
});

const ListSubheader = styled(MuiListSubheader)({
  backgroundColor: grey[700],
  fontWeight: 'bold',
  paddingTop: 2,
  paddingBottom: 2,
  color: 'white',
  lineHeight: 'normal',
  textTransform: 'uppercase',
});

const ListItem = styled(MuiListItem)({
  '&:hover': {
    backgroundColor: blue[500],
  },
});

const ListItemText = styled(MuiListItemText)({
  lineHeight: 'normal',
  marginTop: 3,
  marginBottom: 3,
});

const ListItemTextPrimary = styled(Typography)({
  float: 'left',
  color: 'white',
  textTransform: 'capitalize',
  fontSize: '1rem',
  lineHeight: 'normal',
  fontWeight: 500,
});

const ListItemTextSecondary = styled(Typography)({
  float: 'right',
  color: grey[300],
  textTransform: 'capitalize',
  fontSize: '0.75rem',
});

const ResultItem = ({ item, handler }) => {
  let path;
  switch (item.type) {
    case EntityTypes.APPLICATION:
      path = generatePath(Routes.SINGLE_APPLICATION, { id: item.id });
      break;
    case EntityTypes.TECHNOLOGY:
      path = generatePath(Routes.SINGLE_TECHNOLOGY, { id: item.id });
      break;
    case EntityTypes.VENDOR:
      path = generatePath(Routes.SINGLE_VENDOR, { id: item.id });
      break;
    case EntityTypes.CAPABILITY:
      path = generatePath(Routes.SINGLE_CAPABILITY, { id: item.id });
      break;
    case EntityTypes.PERSON:
      path = generatePath(Routes.SINGLE_PERSON, { id: item.id });
      break;
    case EntityTypes.SERVICE:
      path = generatePath(Routes.SINGLE_SERVICE, { id: item.id });
      break;
    case EntityTypes.COMPONENT:
      path = generatePath(Routes.SINGLE_COMPONENT, { id: item.id });
      break;
    case EntityTypes.BUSINESS:
      path = generatePath(Routes.SINGLE_BUSINESS, { id: item.id });
      break;
    case EntityTypes.PORTFOLIO:
      path = generatePath(Routes.SINGLE_PORTFOLIO, { id: item.id });
      break;
    case EntityTypes.TEAM:
      path = generatePath(Routes.SINGLE_TEAM, { id: item.id });
      break;
    default:
      log.error('was not able to resolve a path for search result');
  }

  return (
    <ListItem component={Link} to={path} onClick={handler}>
      {/* <ListItemIcon>
      <InboxIcon />
    </ListItemIcon> */}
      <ListItemText
        primary={
          <ListItemTextPrimary component="span" variant="body1" color="textPrimary">
            {item.name}
          </ListItemTextPrimary>
        }
        secondary={
          <ListItemTextSecondary component="span" variant="body1" color="textPrimary">
            {item.type}
          </ListItemTextSecondary>
        }
      />
    </ListItem>
  );
};

const Results = ({ results, handler, loading }) => {
  const resultsSorted = groupBy(results, 'type');
  const resultsArray = [];
  Object.keys(resultsSorted).map((key) => resultsArray.push({ type: key, items: resultsSorted[key] }));

  if (loading) return <LinearProgress />;

  return (
    <List component="nav" aria-label="Search Results">
      {resultsArray.map((types) => (
        <React.Fragment key={types.name}>
          <ListSubheader>{types.name}</ListSubheader>
          {types.items.map((item) => (
            <ResultItem key={item.id} item={item} handler={handler} />
          ))}
        </React.Fragment>
      ))}
    </List>
  );
};

const SearchModal = ({ open, handler }) => {
  const [results, setResults] = React.useState([]);
  const [loadingState, setLoadingState] = React.useState(false);

  const handleNavigate = () => {
    handler();
    setResults([]);
  };

  return (
    <Modal
      open={open}
      onClose={handleNavigate}
      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
    >
      <Paper>
        <SearchForm callback={setResults} loadingCallBack={setLoadingState} />
        <Results handler={handleNavigate} results={results} loading={loadingState} />
      </Paper>
    </Modal>
  );
};

export default SearchModal;
